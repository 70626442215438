<template>
  <v-layout wrap justify-center>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 lg8 pt-6>
      <v-card
        class="mx-auto elevation-2 pa-6"
        max-width="1088px"
        align="center"
        outlined
      >
        <v-layout wrap>
          <v-flex
            xs12
            sm6
            align-self-start
            class="text-left nsbold"
            style="font-weight: 700; font-size: 20px"
          >
            <v-icon class="pr-2" color="#797979">mdi-account-plus</v-icon>
            Add New Item
            
            <v-layout wrap justify-center>
              <v-flex
                xs12
                py-4 
                align-self-center
                text-left
                class="text-left nsregular"
                v-if="lastOne"
              >
                <span class="nsbold" style="font-size: 18px" v-if="lastOne">
                  അടുത്ത ബാർകോഡ് : {{ lastOne }}
                </span>
              </v-flex>
            </v-layout>
            </v-flex
          >
          <v-flex
            xs12
            sm6
            align-self-start
            text-center
            class="text-left nsregular"
          >
            <!--  -->

            <v-text-field
              v-model="keyword"
              @keyup.enter="getLastQR()"
              dense
              label="അഡ്മിഷൻ നമ്പർ ഇവിടെ തിരയുക  "
              outlined
              hide-details
              title="Search with Ad.no to get next barcode id"
              class="rounded-xl"
              clearable
            ></v-text-field>
            <!-- <span   style="font-size: 10px">
              അടുത്ത ലേബൽ ഐഡി ലഭിക്കാൻ, വിദ്യാർത്ഥി പ്രവേശന നമ്പർ ഇവിടെ ടൈപ്പ്
              ചെയ്യുക
            </span> -->
            <!-- <span class="nsbold" style="font-size:18px;" v-if="lastOne"> 
              Next Barcode Id : {{ lastOne }}
            </span> -->
          </v-flex>
          <!-- <v-flex
            xs4
            class="text-left nssbold"
            style="font-weight: 400; color: #797979; font-size: 18px"
            @click="$router.push({ path: 'student' })"
          >
            <v-icon class="pr-2">mdi-school-outline</v-icon>Students
            List</v-flex
          > -->

          <v-spacer></v-spacer>
        </v-layout>
        <v-form ref="form">
          <v-layout wrap pt-6>
            <v-flex xs12 lg6 sm6>
              <!-- <v-flex
                xs12
                align-self-start
                class="text-left nsbold"
                style="font-weight: 700; font-size: 20px"
              >
                <v-icon class="pr-2" color="#797979">mdi-account-plus</v-icon>
                Add New Item</v-flex
              > -->
              <!-- <v-flex xs12 text-left pl-4 pb-4>
                <span class="text-left nsregular" style="font-size: 18px"
                  >Scan Bar Code</span
                >
              </v-flex> -->
              
              <v-layout wrap style="font-weight: 400">
                <v-flex xs12>
                  <v-text-field
                    v-model="user.QR"
                    id="tqr"
                    ref="tqrr"
                    autofocus
                    dense
                    readonly
                    label="Scan Bar Code"
                    outlined
                    class="px-md-4 rounded-xl"
                  ></v-text-field>
                  <!-- <v-text-field
                    v-model="user.QR"
                    autofocus
                    dense
                    label="Scan Bar Code"
                    outlined
                    class="px-4"
                    @change="qr(user.QR)"
                  ></v-text-field> -->
                </v-flex>
              </v-layout>
              <v-layout wrap style="font-weight: 400">
                <v-flex xs12 mx-md-4>
                  <v-select
                    :items="kindofCloth"
                    v-model="user.clothType"
                    label="Cloth Type"
                    outlined
                    dense
                    item-text="name"
                    item-value="_id"
                    class="rounded-xl"
                    :rules="[rules.required]"
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center>
                <v-flex xs12 align-self-center class="nsregular">
                  <v-btn
                    style="font-size: 14px; font-weight: 400;"
                    color="#766BC0"
                    dark block
                    class="rounded-xl"
                    depressed
                    large
                    @click="add(student)"
                    >SUBMIT
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 lg6 sm6>
              
              <!-------------------------->
              <!-- <v-flex xs12 text-left pl-4 pb-4>
                <span class="text-left nsregular" style="font-size: 18px"
                  >Enter Item Information</span
                >
              </v-flex> -->
              <v-layout wrap style="font-weight: 400">
                <!-- <v-flex xs5>admissionNo : </v-flex>
                    <v-flex xs7>{{ student.admissionNo }}</v-flex> -->
                <v-flex xs12>
                  <v-text-field
                    v-model="student.admissionNo"
                    dense
                    label="Admission.No"
                    class="px-4"
                    readonly
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap style="font-weight: 400">
                <!-- <v-flex xs5>Name : </v-flex>
                    <v-flex xs7>{{ student.fname }} {{ student.lname }}</v-flex> -->
                <v-flex xs12>
                  <v-text-field
                    v-model="student.name"
                    dense
                    label="Name"
                    class="px-4"
                    readonly
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap style="font-weight: 400">
                <!-- <v-flex xs5>Name : </v-flex>
                    <v-flex xs7>{{ student.Class }}-{{ student.division }}</v-flex> -->
                <v-flex xs12 lg6>
                  <v-text-field
                    v-model="student.Class"
                    dense
                    label="Class"
                    class="px-4"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 lg6>
                  <v-text-field
                    v-model="student.division"
                    dense
                    label="Division"
                    class="px-4"
                    readonly
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <!-------------------------->
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
      <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="snackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
      <v-snackbar
        :timeout="3000"
        :value="true"
        right
        top
        color="success"
        v-model="snackbar1"
      >
        <v-layout align-center pr-4 wrap>
          <v-flex xs3>
            <v-img
              height="45px"
              contain
              :src="require('./../../assets/images/success.png')"
            ></v-img>
          </v-flex>
          <v-flex xs6>
            <span style="font-size: 20px"> {{ msg }}</span>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-snackbar
        :timeout="3000"
        :value="true"
        right
        top
        color="red"
        v-model="snackbar2"
      >
        <v-layout align-center pr-4 wrap>
          <v-flex xs3>
            <v-img
              height="45px"
              contain
              :src="require('./../../assets/images/close.png')"
            ></v-img>
          </v-flex>
          <v-flex xs9>
            <span style="font-size: 20px"> {{ msg }}</span>
          </v-flex>
        </v-layout>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      student: [], //incoming data frm backend
      snackbar: false,
      snackbar1: false,
      snackbar2: false,
      kindofCloth: null,
      msg: null,
      //input frm user
      user: {
        clothType: null,
        kindofCloth: null,
        preferences: null,
        Remark: null,
      },
      ServerError: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      keyword: null,
      lastOne: null,
      watchAr: [],
      rnd: "flager",
      tempQR: null,
    };
  },
  watch: {
    // keyword() {
    //   this.getLastQR();
    // },
    watchAr() {
      if (this.watchAr.length == 1) this.qr(this.tempQR);
      else if (this.watchAr.length > 1) {
        this.watchAr = [];
        this.qr(this.tempQR);
      }
    },
  },
  beforeMount() {
    this.getData();
    this.testBarcode();
  },
  methods: {
    getLastQR() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/accept/qr/1",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          keyword: this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.lastOne = response.data.data;
          } else {
            this.lastOne = null;
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
    testBarcode() {
      var barcode = "";
      var interval = "";
      var vm = this;
      vm.QR = null;

      document.addEventListener("keydown", function (evt) {
        if (interval) clearInterval(interval);
        if (evt.code == "Enter") {
          if (barcode) vm.handleBarcode(barcode);
          barcode = "";
          return;
        }

        if (evt.code != "Shift") barcode += evt.key;
        interval = setInterval(() => (barcode = ""), 200);
      });
    },

    handleBarcode(scanned_barcode) {
      if (this.$route.name == "mapItem") {
        this.watchAr.push(this.rnd);
        this.lastOne = null;
        this.keyword = null;
        document.querySelector("#tqr").innerHTML = scanned_barcode;
        this.QR = scanned_barcode;
        this.tqr = scanned_barcode;
        this.user.QR = scanned_barcode;
        this.tempQR = scanned_barcode;
        // this.qr(scanned_barcode);
      }

      // document.querySelector("#tqr").innerHTML = scanned_barcode;
    },
    //   getData() {
    //   axios({
    //     method: "post",
    //     url: "/accept/qr",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       QR:this.user.QR,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         this.student = response.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err());
    //     });
    // },
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/cloth/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.kindofCloth = response.data.data;

            this.msg = response.data.msg;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
    clearall() {
      this.tqr = null;
      this.Qrcode = null;
      this.student = {};
      this.stdata = {};
      this.clothes = null;
      this.user.clothType = null;
      this.user.QR = null;
    },
    async add(id) {
      if (this.$refs.form.validate()) {
        this.user["id"] = id._id;

        await axios({
          method: "post",
          url: "/laundryadmin/add/cloth",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: this.user,
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.snackbar1 = true;
              // this.$router.go(-1);
            } else {
              this.snackbar2 = true;
              this.msg = response.data.msg;
            }
            this.clearall();
          })
          .catch((err) => {
            console.log(err);
            this.ServerError = true;
          });
      }
    },
    qr(barcode) {
      this.tempQR = null;
      this.appLoading = true;
      var text= barcode
      barcode = text.replace("Shift","")
      this.user.QR = barcode;
      axios({
        method: "post",
        url: "/accept/qr",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.user,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;
            this.student = response.data.data;

            // this.user.QR= null
            // document.querySelector("#tqr").innerHTML = null;
            // this.msg = response.data.msg;
            // this.snackbar = true;
          } else {
            this.appLoading = false;
            this.student = {};
            this.user.QR = null;
            this.msg = response.data.msg;
            this.snackbar2 = true;
            document.querySelector("#tqr").innerHTML = null;
          }
        })
        .catch((err) => {
          console.log(err);
          this.ServerError = true;
        });
    },
    show() {
      this.content = this.student;
    },
  },
};
</script>